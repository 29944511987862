import Cookies from 'js-cookie';
import sanitizeHtml from 'sanitize-html';
import {
  DATE_FORMAT,
  SERVER_DATE_FORMAT,
  REDIRECT_TO_MAIN_PAGE,
  FORM_TYPES,
  LOGOUT_PAGE,
  NEW_POSITION,
  POSITION_DATE_FORMAT, MAIN_PAGE, BANNER_MAIN_PAGE_TYPE, BANNER_TEXTS, CORRECT_FILE_EXTS, EXPERTS
} from "./common";
import api from "../api";
import moment from "moment";
import * as cookie from "cookie";
import queryString from 'query-string';


export const getAccessFrontEndToken = () => {
  return Cookies.get('vl_token') || null;
};


// export const checkAuth = (req) => {
//   if (!req?.cookies?.vl_token) {
//     return REDIRECT_TO_MAIN_PAGE
//   }
//   return null
// }

export const getFormattedDate = (date) => {
  const momentDate = moment(date,  SERVER_DATE_FORMAT);
  return momentDate.isValid() ? momentDate.format(DATE_FORMAT) : date;
}

export const setRequestParams = (data) => {
  if (!data) {
    return '';
  }
  if (!Object.keys(data).length) {
    return '';
  }
  return '?' + new URLSearchParams(data).toString();
}

export const clearHtmlAndSubstr = (value, withEllipsis = false) => {
  if (!value) {
    return value;
  }
  //const formattedValue = value.replace(/<\/?[^>]+(>|$)/g, "").substr(0, length);
  //return (formattedValue.length === length) ? `${formattedValue.substr(0, formattedValue.lastIndexOf(' '))}${withEllipsis ? '...' : ''}` : formattedValue;
  return sanitizeHtml(value, {
    allowedTags: [
      "b", "br", "i", "strong", "u", "ul", "ol", "li"
    ],
    allowedAttributes: {},
  });
}

export const sanitizeVacancyText = (value) => {
  if (!value) {
    return value;
  }
  return sanitizeHtml(value, {
    allowedTags: [
      "article", "aside", "h1", "h2", "h3", "h4", "h5", "h6", "section", "blockquote", "div",
      "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre", "ul", "a", "abbr",
      "b", "br", "cite", "code", "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc",
      "ruby", "s", "samp", "small", "span", "strong", "sub", "sup", "u", "caption",
      "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "img"
    ],
    allowedAttributes: {
      a: [ 'href', 'name', 'target', 'class' ],
      p: [ 'class', 'id', 'style' ],
      h1: [ 'class', 'id', 'style' ],
      h2: [ 'class', 'id', 'style' ],
      h3: [ 'class', 'id', 'style' ],
      h4: [ 'class', 'id', 'style' ],
      h5: [ 'class', 'id', 'style' ],
      h6: [ 'class', 'id', 'style' ],
      div: [ 'class', 'id', 'style' ],
      table: [ 'class', 'id', 'style' ],
      thead: [ 'class', 'id', 'style' ],
      tbody: [ 'class', 'id', 'style' ],
      tr: [ 'class', 'id', 'style' ],
      th: [ 'class', 'id', 'style' ],
      td: [ 'class', 'id', 'style' ],
      pre: [ 'class', 'id', 'style' ],
      blockquote: [ 'class', 'id', 'style' ],
      cite: [ 'class', 'id', 'style' ],
      figure: [ 'class', 'style' ],
      figcaption: [ 'class', 'style' ],
      img: [ 'src', 'class', 'style', 'srcset' ],
      span: [ 'class', 'id', 'style' ]
    },
  });
}

export const fetchReferences = async () => {
  let result = null;
  try {
    result = await api.get(`/references`, {}, {});
  } catch (e) {
    //console.log('references', e);
  }
  return result?.data;
}

export const postFetchData = async (url, query = {}, body = {}, isFormData = false, isAuthorized = false) => {
  try {
    return await api.post(url, query, body, null, isFormData, isAuthorized);
  } catch (e) {
    return e
  }
}

export const turnOffLoader = (callback, duration) => {
  setTimeout(() => {
    callback();
  }, duration > 1000 ? 0 : 1000 - duration)
}

export const formattedFormValues = values => {
  return Object.keys(values).reduce((obj, cur) => ({...obj, [cur]: values[cur] || null}), {})
}

export const sendFormEvents = async ({uuid, event_type, form_type, vacancy_id = null, values}) => {
  const data = {
    event_type: event_type,
    data: {
      form_type: form_type,
      filling_id: uuid,
      vacancy_id: vacancy_id,
      form_fields: formattedFormValues(values)
    }
  }
  await postFetchData('/te', {}, data, false, getAccessFrontEndToken() !== null);
}

export const makeUserFullName = (lastName = null, firstName = null, patronymic = null) => {
  return [lastName, firstName, patronymic]
    .map(item => (item || '').trim())
    .filter(Boolean)
    .join(' ');
}

export const isEmptyObject = (obj) => {
  return !Object.keys(obj).length;
}

export const objToStr = (obj) => {
  return Object.entries(obj).sort().toString()
}

export const getUserProfile = async (vl_token, token, res, query) => {
  let profile = null;
  let favoriteVacancies = []

  if (vl_token || token) {
    try {
      profile = await api.get(`/me/profile`, {}, {}, {Authorization: `Bearer ${token || vl_token}`}, false, true);
      favoriteVacancies = await api.get(`/me/dream_vacancies`, {}, {}, {Authorization: `Bearer ${token || vl_token}`}, false, true);
    } catch (e) {
      if (e.isBadToken) {
        res.writeHead(302, {location: queryString.stringifyUrl({url: LOGOUT_PAGE, query})});
        res.end();
        return e;
      }
    }
  }
  return {
    ...profile?.data,
    favoriteVacancies: favoriteVacancies?.data || []
} || null;
}

export const getHomePageData = async (context) => {
  const cookies = cookie.parse(context.req ? context.req.headers.cookie || "" : document.cookie);
  const {vl_token} = cookies;
  const {token} = context.query;

  let result = null;

  try {
    result = await api.get(`/vacancies/starred`, { page_size: 5 }, {}, { Authorization: `Bearer ${token || vl_token}` }, false, true);
  } catch (e) {
    //console.log('starred', e);
  }

  let profile = await getUserProfile(vl_token, token, context.res, context.query);

  return {
    props: {
      vacancies: result?.data.map(v => ( {...v, date: getFormattedDate(v?.date)})) || null,
      profile: profile || null
    },
  }
}

export const getUserPositions = async (vl_token, token = null) => {
  let positions = await api.get(`/me/positions`, {}, {}, { Authorization: `Bearer ${token || vl_token}` }, false, true);

  positions = positions.data.map((position, index) => {
    let date_from_for_title = !position?.date_from ? null :
      moment(position?.date_from, POSITION_DATE_FORMAT).format('D MMMM YYYY')
    date_from_for_title = !date_from_for_title ? null : date_from_for_title.split(' ')
    date_from_for_title = !date_from_for_title ? null : `${date_from_for_title[1]} ${date_from_for_title[2]}`

    let date_to_for_title = !position?.date_to ? null :
      (position?.date_to === 'present' ? 'настоящее время' : moment(position?.date_to, POSITION_DATE_FORMAT).format('MMMM YYYY'))

    const title_date =  `${date_from_for_title ? `. C ${date_from_for_title}${date_to_for_title ? 
      ` по ${date_to_for_title}` : (index === 0 ? ' по настоящее время' : '')}.` : ''}`

    const title = position.title ? `${position.title}${title_date}`
      : (index === 0 ? `Текущая позиция${title_date}` : `Предыдущая позиция (увеличит точность подбора)${title_date}`)

    return {
      ...position,
      title_with_date: title,
      range_date: [
        !position.date_from ? null : moment(position?.date_from, 'YYYY-MM-DD').format(POSITION_DATE_FORMAT),
        !position.date_to || position?.date_to === 'present' ? null : moment(position?.date_to, 'YYYY-MM-DD').format(POSITION_DATE_FORMAT)],
    }
  })

  return positions;
}

export const redirectToMain = (res, query = null) => {
  let location = MAIN_PAGE;
  if (query) {
    if (query['vluid'] || query['token']) {
      delete query['vluid'];
      delete query['token'];
    }
    location = queryString.stringifyUrl({url: location, query})
  }

  res.writeHead(302, { location } );
  res.end();
  return {
    props: {
    }
  }
}