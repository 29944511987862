import base from './base';


const SuperFetch = {};
['get', 'post', 'put', 'patch', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method.toUpperCase());
});


export default SuperFetch;
