import axios from 'axios';

import {API_URL, PROXY_PREFIX, BASE_URL, ERROR_CODES} from "../helpers/common";
import {setRequestParams} from "../helpers/utils";
import {destroyCookie, parseCookies} from "nookies";

const METHODS_WITH_BODY = ['POST', 'PUT', 'PATCH', 'DELETE'];

async function base(method, url, queryParams = {}, data = {}, customHeaders = null, isFormData = false, isAuthorized = false, proxyPrefix = null) {
  const cookies = parseCookies();
  const { vl_token } = cookies;
  //const vl_token = "1189f642aff783996b5d28ead95bf00e";

  let headers = {
    'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
  }

  if (isAuthorized) {
    headers.Authorization = `Bearer ${vl_token}`
  }

  if (customHeaders) {
    customHeaders = {...headers, ...customHeaders}
  }

  // const full_url = `${API_URL}${url}${setRequestParams(queryParams)}`;
  const full_url = `${API_URL}${proxyPrefix || PROXY_PREFIX}${url}${setRequestParams(queryParams)}`;

  const request = {
    method: method,
    url: full_url,
    data: METHODS_WITH_BODY.includes(method) ? (isFormData ? data : JSON.stringify(data)) : null,
    headers: customHeaders || headers
  };

  const instance = axios.create();

  instance.interceptors.request.use( config => {
    return {
      ...config,
      meta: {
        ...config.meta,
        requestStartedAt:  new Date().getTime()
      }
    }
  })

  instance.interceptors.response.use(async (response) => {
    return {
      ...response?.data,
      responseDurationMs: new Date().getTime() - response.config.meta.requestStartedAt
    };
  }, async (error) => {
    if (error?.response?.status === 401) {
      if (process.browser) {
        window.location = '/logout'
      } else {
        return Promise.reject({
          isBadToken: true
        })
      }
    }
    return Promise.reject({
      status: error?.response?.status || 500,
      code: error?.response?.data?.error?.code || 500,
      message: {
          [ERROR_CODES?.not_send_message]: 'Не удалось отправить сообщение на ваш почтовый адрес. Пожалуйста, обратитесь в службу поддержки.'
        }[error?.response?.data?.error?.code] || error?.response?.data?.error?.message || '',
      data: error?.response?.data?.error?.data || null,
      responseDurationMs: new Date().getTime() - error.config.meta.requestStartedAt,
    });
  });

  return instance.request(request);
}

export default base;
